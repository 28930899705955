<template lang="pug">
  v-select(
    multiple
    :label="label"
    :placeholder="placeholder"
    :value="value"
    @input="handleChange"
    :items="items"
    :item-value="itemValue"
    :item-text="itemText"
    :error-messages="errorMessages"
    :menu-props="{ offsetY: true }"
    :outlined="outlined"
    :dense="dense"
  )
    template(#prepend-item)
      v-list-item(@click="toggleAll")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="selectedCount != 0"
            :indeterminate="!selectedCount ? false : !isAllItemsSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все

    template(#selection="{item, index}" v-if="hideAfter || textAllSelected")
      span(v-if="index === 0") {{showTextAllSelected ? textAllSelected : item[itemText]}}
      span(v-else-if="!showTextAllSelected && (!hideAfter || index < hideAfter)") {{ ', ' + item[itemText] }}
      span.ml-3.grey--text.caption(v-else-if="!showTextAllSelected && index === hideAfter") (ещё + {{ selectedCount - hideAfter }})
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    dense: Boolean,
    outlined: Boolean,
    textAllSelected: {
      type: String,
      default: '',
    },
    hideAfter: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    selectedCount() {
      return this.value?.length || 0;
    },
    isAllItemsSelected() {
      return this.value?.length === this.items.length;
    },
    showTextAllSelected() {
      return this.isAllItemsSelected && this.textAllSelected;
    },
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) this.handleChange([]);
        else this.handleChange(this.items.map(item => item[this.itemValue]));
      });
    },
    handleChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
